import Levi2 from './Components/AFrame/Levi2';
import SampleTextComponent from './Components/TextFrames/SampleTextComponent';

const pages = [{
  type: 'image',
  src: 'https://i.imgur.com/1eYXeaS.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/wmhNLxZ.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/4CEGzPC.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/2Qwwtte.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/jpk3xs4.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/dabOLIr.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/E2VZRw3.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/r34Vtzh.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/LzESFQq.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/HBFmukv.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/UUBMpFr.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/uoQez4f.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/QOu12Dp.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/FFfxQDL.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/rQKCZkI.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/dJbS1SQ.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/88cG40H.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/wnOT7LX.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/9jT7nVy.png'
}, {
  type: 'image',
  src: 'https://i.imgur.com/Voo9uge.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/nUH1sgC.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/195Ua2s.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/lWdMTrN.png'
}, {
  type: 'image',
  src: 'https://i.imgur.com/uGp0bar.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/XH0k0sc.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/hGXfSoj.png'
}, {
  type: 'image',
  src: 'https://i.imgur.com/tFhxeni.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/LGAtLGl.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/Ip62B4u.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/b4sakrR.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/HQC0bFk.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/0TedAid.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/EfnTvrI.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/zf2k08U.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/VE7FqzO.png'
}, {
  type: 'image',
  src: 'https://i.imgur.com/iwnDH3i.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/apZavCR.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/g2U6Ney.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/cGiYYiv.png'
}, {
  type: 'image',
  src: 'https://i.imgur.com/SQ10XNn.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/rn5BUMB.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/oZgO2Ex.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/5PqRhbe.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/f1VB1Lw.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/mNhmNxO.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/IfMP2hA.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/EDihFOe.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/vfaueFx.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/yET1I8Z.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/Xrpelll.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/nUH1sgC.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/QjxJwo7.jpg'
}, {
  type: 'image',
  src: 'https://i.imgur.com/IA9YXwl.jpg'
}, {
  type: 'aframe',
  src: Levi2,
  audio: '/audio/meteor-theory.mp3'
}, {
  type: 'text',
  src: SampleTextComponent
}];

export default pages;
